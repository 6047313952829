(function ($) {
  Drupal.behaviors.loyaltyOfferSignupV1 = {
    attached: false,
    attach: function () {
      if (this.attached) {
        return;
      }
      this.attached = true;

      $(document).on('cbox_complete', function () {
        if ($('body').find('.js-popup-enabled').length > 0) {
          $('body').addClass('non-intrusive-popup-enabled');
        }
      });
    }
  };
})(jQuery, Drupal);
